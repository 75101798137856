import React from 'react';

const NotFound = () => {
  const externalLinks = [
    {
      title: 'Machine Learning and Astronomy',
      url: 'https://github.com/WLNORGREN/TALOS/tree/master/Astronomy_Projects'
    },
    {
      title: 'Hexadecimal Calculator',
      url: 'https://github.com/WLNORGREN/TALOS/tree/master/Calculators'
    },
    {
      title: 'Interactive Physics',
      url: 'https://github.com/WLNORGREN/TALOS/tree/master/Physics_Simulations'
    }
  ];

  return (
    <div>
      <h3>Past Projects</h3>
      <ul>
        {externalLinks.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotFound;
