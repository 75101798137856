import React from 'react';

const Contact = () => {



    const divStyle = {
        
        marginLeft: '5%',
        marginRight: '5%'
    
    }

    const ulStyle = {
    listStyleType: 'none',
    padding: 0,
     
  };

  const liStyle = {
    fontWeight: 'bold',
    marginBottom: '2vw', 
    marginLeft: '2.5%',
    fontSize: '18px'
  };

  const linkStyle = {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
    marginLeft: '2.5%',
    fontSize: '18px'
  };
  
  const emailStyle = {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: 'black',
    fontSize: '18px'
  };
  
  
  return (
    <div style={divStyle}>
      <ul style={ulStyle}>
        <li style={liStyle}>Name: Wolf Norgren</li>
        <li style={liStyle}>Title: Chief Information Officer</li>
        <li style={liStyle}>Phone:  906-201-4202 </li>
        <li style={liStyle}>E-mail: <a href="mailto:bibliocortex@protonmail.com?subject=Example" style={emailStyle}>bibliocortex@protonmail.com</a></li>
        <li><a href="https://github.com/WLNORGREN/TALOS" style={linkStyle}>LinkedIn</a></li>
    </ul>

    <footer>
      © 2023, BIBLIOCORTEX LLC. All rights reserved.
    </footer>
    </div>
  );
};

export default Contact;
