import React from 'react';
import { Link } from 'react-router-dom';
import logo from './pages/img_3800.1f1b05de.jpg';


const Navigation = () => {

  const responsiveImageStyle = {
    maxWidth: '100%',
 
  };
  const listStyle = {
    display: 'flex',
    justifyContent: 'center', // Adjust as needed (e.g., 'flex-start', 'flex-end')
    listStyleType: 'none',
    padding: 0
  };
  
  const linkStyle = {
    borderLeft: '2px solid black',
    
    paddingRight: '2rem', 
    paddingLeft: '2rem',// Optional: Add left padding to create some spacing between the border and the link text
    display: 'block', // Optional: Make the links take up the entire width of the container
    textDecoration: 'none', // Optional: Remove underlines from the links
    color: 'black', // Optional: Set the link colo
  
    fontWeight: 'bold',
    fontSize: '2vw',
    lineHeight: '2.5rem' 
      };

  const listItemStyle = {
    
    fontSize: '1.8vw' // Adjust as needed for spacing between items
  };
  return (
    <div>
    <img src={logo} style={responsiveImageStyle} className="App-logo" alt="logo" />
    <nav>
      <ul style={listStyle}>
        <li style={listItemStyle}>
          <Link to="/about" style={linkStyle}>WHAT WE DO</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/contact" style={linkStyle}>CONTACT</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/services" style={linkStyle}>EXPERIENCE</Link>
        </li>
        <li style={listItemStyle}>
          <Link to="/pastprojects" style={linkStyle}>PAST PROJECTS</Link>
        </li>
   
     
      </ul>
    </nav>
 
    </div>
  );
};

export default Navigation;

